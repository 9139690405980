$body-color: black;
$body-color-2: white;
$color-black: #2b2d36;

html,
body {
  padding: 0;
  margin: 0;

  /* Hide scrollbar for Chrome, Safari and Opera */

  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  color: $body-color;
  overflow-x: hidden;
  overscroll-behavior: none;
  line-height: 1.2;

  ::-webkit-scrollbar {
    display: none;
  }
}

.scroll__content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100vh;
  // mix-blend-mode: difference;
  overflow: hidden;
  width: 700%;
}

.section {
  height: 100vh;
  width: 100vw;
  display: block;
  min-width: 100vw;
}

// WORK

#work {
  header {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
    margin-left: 35%;

    h2 {
      font-size: 20em;
      width: 100%;
      min-width: 800px;
    }

    h3 {
      font-size: 5em;
      font-weight: normal;
    }
  }

  .marquee__part {
    color: $color-black;
  }

  .marquee__inner {
    border-bottom: 2px solid $color-black;
  }
}

.section__work {
  display: flex;
  height: 100%;
  width: 100%;

  .col-one-third {
    width: 300px;
    text-align: center;
    overflow: hidden;
    margin: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#gallery {
  background-color: white;
}

.modal__content {
  mix-blend-mode: normal;
  position: fixed;
  width: 100vw;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 999;
  background-color: black;
  padding: 4%;

  img {
    width: 60%;
  }

  #asset__title {
    color: white;
    font-size: 8em;
  }

  #asset__description {
    color: white;
    font-size: 3em;
  }

  #asset__number {
    color: white;
  }

  #asset__link {
    color: white;
    font-size: 5em;
    text-transform: uppercase;
  }

  .content--flex {
    display: flex;
  }
}
