*,
*::after,
*::before {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  font-size: 15px;
  --font-size-p: 2rem;
  --font-size-h3: 10em;
}

/* .modal__content{display:none} */
body {
  margin: 0;
  --color-text: black;
  --color-black: black;
  --color-bg: white;
  --color-1: black;
  --color-2: purple;
  --color-link: white;
  --color-link-hover: blue;
  color: var(--color-text);
  background-color: var(--color-bg);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button[type=submit] {
  border: 1px solid black;
  background-color: white;
  padding: 1em;
  text-transform: uppercase;
  font-family: revert;
}

.menu {
  font-size: 3em;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: auto;
  opacity: 1;
  mix-blend-mode: difference;
}

.menu a {
  color: white;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
  content: "";
  position: fixed;
  z-index: 1000;
}

.js .loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-1);
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100vw;
  padding-top: 3vh;
  padding-left: 6vw;
  padding-right: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  mix-blend-mode: difference;
}

nav ul {
  list-style: none;
  display: flex;
}

nav ul li {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

nav ul li:last-of-type {
  margin-right: 0;
}

.small__divider {
  margin-bottom: 1em;
  margin-top: 1em;
  height: 3px;
  width: 10em;
  background-color: white;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

a {
  text-decoration: none;
  color: rgb(66, 132, 255);
  outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
  /* Provide a fallback style for browsers
	 that don't support :focus-visible */
  outline: none;
  background: lightgrey;
}

a:focus:not(:focus-visible) {
  /* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
  background: transparent;
}

a:focus-visible {
  /* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
  outline: 2px solid blue;
  background: transparent;
}

.p-0 {
  padding: 0 !important;
}

#open {
  max-width: 20em;
}

.work.animate__fade_in {

  opacity: 0;
}

/* contact section  */
.contact_info h2 {
  text-transform: uppercase;
}

.contact_info h3 {
  width: max-content;
  border-bottom: 4px solid black;
}

.contact_info h3:nth-of-type(1) {
  margin-bottom: 0.5em;
  margin-top: 0.2em;
}

.contact section:nth-of-type(1) {
  flex-direction: column;
}

.title {
  width: 45%;
}

.half {
  width: 50%;
}

.contact section {
  min-height: 100vh;
  padding-bottom: 10vh;
}

#contact {
  background-color: white;
  color: black;
}

#contact h3 {
  font-size: 3em;
}

/* Form reset */
form {
  padding-top: 2em;
}

form input,
form select,
form textarea,
form fieldset,
form optgroup,
form label,
.StripeElement {
  font-family: inherit;
  font-size: 2rem;
  font-weight: bold;
  color: inherit;
  border: none;
  border-radius: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

form label,
form legend {
  display: none;
}

/* border, padding, margin, width */
form input,
form select,
form textarea,
.StripeElement {
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.25em 0.5rem;
  margin-bottom: 1rem;
}

form input:focus,
form select:focus,
form textarea:focus,
.StripeElement:focus {
  background-color: white;
  outline-style: none;
}

form input:focus::placeholder,
form select:focus::placeholder,
form textarea:focus::placeholder,
.StripeElement:focus::placeholder {
  transition: all 0.1s ease-in;
  opacity: 0;
}

form [type="text"],
form [type="email"],
.StripeElement {
  width: 100%;
}

form [type="button"],
form [type="submit"],
form [type="reset"] {
  width: auto;
  cursor: pointer;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}

form [type="button"]:focus,
form [type="submit"]:focus,
form [type="reset"]:focus {
  outline: none;
}

form select {
  text-transform: none;
}

@media screen and (max-width: 53em) {
  .contact.page>section>.flex {
    flex-wrap: wrap;
  }

  .left,
  .half {
    width: 100%;
  }
}

.content {
  width: 100%;
  height: calc(100vh - 13rem);
}

#logo {
  width: 20vw;

  min-width: 150px;
  mix-blend-mode: normal;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

nav {
  z-index: 999;
  position: fixed;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  /* background-color: var(--color-link); */
}

#nav .links a:nth-of-type(1) {
  padding-right: 1em;
}

.w-100 {
  width: 100%;
}

#services {
  max-width: 100vw;
}

.yellow {
  color: rgb(28, 62, 255);
}

.title {
  text-transform: uppercase;
  font-size: 7vw;
}

.portfolio_item {
  height: 100%;

  /* opacity: 0.6; */
  margin: 1em;
}

body {
  overflow-x: hidden;
}

.info__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dg {
  font-size: 3em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.info__container .block:nth-of-type(1) {
  margin-right: 10%;
}

.cursor {
  display: none;
  z-index: 999999;
}

.marquee__part {
  flex-shrink: 0;
  padding: 0 0.5em;
  /* font-smooth: always; */
}

.marquee {
  color: #eee;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 6.5vw;
  letter-spacing: 10px;
  position: absolute;
  transform: rotate(-90deg);
  width: 21%;
  height: 42%;
}

.marquee__inner {
  padding-bottom: 4vw;
  border-bottom: 3px solid white;
}

footer .split-line {
  text-align: center !important;
}

.marquee__inner {
  -webkit-font-smoothing: antialiased;
  width: 110vh;
  display: flex;
  flex: auto;
  flex-direction: row;
  position: absolute;
  left: 0;
}

#quote {
  font-size: 20.7vw;
  font-weight: 600;
  line-height: 0.8em;
}

.spacer {
  width: 33%;
}

footer {

  padding-top: 20vh !important;
  padding: unset !important;
  border-top: 1px solid #eee;
  width: 100% !important;
  height: 29vh !important;
  display: block;
  background-color: white !important;
  min-height: 0 !important;
  /* min-height: 10vh; */
  color: black !important;
  overflow: hidden !important;
}

.footer_UA {
  width: 98vw;
  height: auto;
  background-color: transparent;
  margin: auto;
}

.footer__links {
  display: flex;
  width: 96vw;
  margin: auto;
  justify-content: space-between;
  z-index: 10;
  position: relative;
  top: 2.2em;
}

.split-line {
  overflow: hidden;
}

#shopify {
  max-width: 200px;
}

/* #about {
  flex-wrap: wrap;
  flex-direction: row;
} */
#about .flex {
  align-items: center;
}

#about .small__divider {
  margin-top: 3em;
  background-color: var(--color-bg);
  height: 3px;
  width: 10em;
}

#about header {
  width: 50%;
}

#about h1 {
  text-transform: uppercase;
  font-size: 2em;
}

#about h3 {
  font-size: max(8vw, 30px);

  text-transform: uppercase;
  /* letter-spacing: .05em; */
  line-height: 0.7em;
}

#about p {
  font-size: var(--font-size-p);
  padding: 1em 0 1em 0;
}

#about .text {
  width: 50%;
  max-width: 40em;
  padding-left: 5em;
}

#about .margin-left {
  margin-left: 20vw;
}

@media (any-pointer: fine) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
    opacity: 0;
    mix-blend-mode: difference;
  }

  .cursor__inner {
    fill: none;
    stroke: white;
    stroke-width: 2px;
  }
}

.d-s-asterisk {
  stroke: white;
}

.grid {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
  padding: 1rem;
}

.grid__item {
  margin: 0 0 4rem;
}

.grid__item-img {
  max-width: 100%;
  display: block;
  cursor: pointer;
}

.grid__item-img--large {
  pointer-events: none;
  position: fixed;
  opacity: 0;
}

.grid__item-title {
  font-size: 2.25rem;
  font-weight: 400;
  margin: 1.25rem 0;
  line-height: 1;
  color: var(--color-title);
}

.grid__item-text {
  color: var(--color-grid-text);
  font-size: 0.95rem;
}

.fullview {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  padding: 2rem;
  z-index: 3;
  grid-template-columns: 100%;
  pointer-events: none;
  z-index: 9999;
}

.fullview__item {
  pointer-events: none;
  grid-area: 1 / 1 / 2 / 2;
  display: grid;
  grid-template-columns: 50% 50%;
}

.fullview__item--current {
  pointer-events: auto;
}

.fullview__item-title {
  margin: 0 0 0 0.25rem;
  line-height: 1;
  font-size: 4rem;
  font-weight: 400;
  opacity: 0;
  align-self: end;
  color: var(--color-title);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fullview__close {
  position: fixed;
  top: 5rem;
  right: 2rem;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  color: #fff;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
  z-index: 9999;
}

.fullview__item--current~.fullview__close {
  pointer-events: auto;
}

.fullview__close:focus {
  outline: none;
}

.fullview__close svg {
  fill: currentColor;
}

@media screen and (min-width: 53em) {
  .message {
    display: none;
  }

  .content {
    height: 100vh;
    justify-content: center;
  }

  .grid {
    display: grid;
    grid-template-columns: 31% 36% 33%;
    width: 100%;
    max-width: 1200px;
    margin: 10vh auto 0;
    padding: 0;
  }

  .grid__item {
    padding: 0 4.5vw;
  }

  .grid__item:first-child {
    padding-top: 35%;
  }

  .grid__item:nth-child(3) {
    padding-top: 40%;
  }
}

@media screen and (min-width: 53em) {
  .container-hori {
    position: relative;
    width: 100%;
    min-height: 100vh;
  }

  .space-holder {
    position: relative;
    width: 100%;
  }

  .sticky {
    position: sticky;
    top: 0;
    height: 100vh;
    width: 200vw;
    overflow-x: hidden;
  }

  .horizontal {
    position: absolute;
    height: 100%;
    will-change: transform;
  }

  .white {
    color: white;
  }

  progress {
    position: fixed;
    bottom: 0;
    left: 0;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
    border: none;

    mix-blend-mode: difference;
    z-index: 2000;
  }

  progress::-webkit-progress-bar {
    background: transparent;
  }

  progress::-webkit-progress-value {
    background: #fff;
    background-attachment: fixed;
  }

  progress::-moz-progress-bar {
    background: #fff;
    background-attachment: fixed;
  }

  .cards {
    position: relative;
    height: 100%;
    padding: 0 0 0 150px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .sample-card {
    position: relative;
    height: 300px;
    width: 500px;
    background-color: #111f30;
    margin-right: 75px;
    flex-shrink: 0;
  }

  #services h2 {
    font-size: 6em;
    margin-bottom: 0.7em;
  }

  #services ul li {
    list-style: none;
    font-size: 2rem;
  }

  #services .block {
    height: 50%;
  }



  .other__services {
    display: flex;
    margin-top: 3em;
  }

  .other__services span {
    font-size: 8em;
    line-height: 1em;
  }

  .other__servives__right a>h2 {
    margin-top: 0.3em;
    font-size: 2.5em !important;
    line-height: auto !important;
  }

  .other__servives__right {
    margin-left: 1em;
  }

  .other__services svg {
    width: 10%;
    min-height: 70px;
  }

  a:hover {
    outline: none;
  }

  #logo {
    width: 10vw;
  }

  /* one-third {
    margin-right: 1em;
    transition: 1s all ease;
    /* max-width: 33.33%; */

  .col-one-third:last-of-type {
    margin-right: 0;
  }

  .content {
    height: 100vh;
  }
}

.content__back {
  position: absolute;
  right: 5vw;
  background: none;
  margin: 0;
  border: 0;
  grid-area: back;
  width: 100px;
  height: 100px;
  align-self: start;
  cursor: pointer;


}

.proxy {
  position: absolute;
  visibility: hidden;
}

#back-btn {

  opacity: 0;
}

/* .content__back:focus,
.content__back:hover {
  outline: none;
  background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='32px' height='22px' viewBox='0 0 32 22' %3E %3Cpath d='M1.301 10.658L11.615.344 14.39 3.12 8.616 8.893H30.73v3.923H8.617l5.773 5.774-2.775 2.774L1.301 11.05a.28.28 0 0 1 0-.392z' stroke='%23ffffff' stroke-width='1px' fill='%23ffffff'/%3E %3C/svg%3E")
    no-repeat 50% 50%;
    
} */

.showfull {
  position: absolute;
}

#arrowscroller {
  color: white;
  bottom: 40px;
  left: 92px;
  font-size: 8em;
  position: fixed;
  mix-blend-mode: difference;
}

#scrollIndicator {
  font-size: 1.52em;
  text-transform: uppercase;
  fill: white;
  mix-blend-mode: difference;
  position: fixed;
  left: -30px;
  bottom: -50px;
  z-index: 99;
}


@media screen and (max-width: 53em) {
  ::-webkit-scrollbar {
    display: none !important;
  }

  nav ul li {
    height: 1em;
  }

  nav img {
    max-height: 8em
  }

  #arrowscroller, #scrollIndicator {
    display: none;
  }

  .content {
    height: 100vh;
  }

  .contact_info>.flex {
    padding-top: 1em;
  }

  .menu {
    flex-direction: column;
  }

  .menu li a {
    font-size: .5em;
    line-height: .5em;
    margin: 0;
    padding: 0;
    ;

  }

  /* .section{
  min-width:120vw;
} */
  .info__container {
    justify-content: flex-start;

  }

  .section__title-text {
    width: 70vw;

  }

  :root {
    --font-size-p: 1em;
  }

  .flex {
    flex-wrap: wrap;
    padding-top: 14vh;
    max-height: 70vh;

  }

  #work header h2 {
    font-size: 6em;

  }

  #work header h3 {
    font-size: 1em;

  }

  #about header, #about .text {
    width: 100%;
  }

  #contact h3 {
    font-size: 1em;
  }

  form input, form select, form textarea, form fieldset, form optgroup, form label, .StripeElement {
    font-size: 1em;
  }

  progress {
    display: none;
  }

  #quote {
    font-size: 20vw;
    letter-spacing: -5px;
  }

  .modal__content #asset__title {
    font-size: 4em;

  }

  .modal__content img {
    width: 100%;
  }

  .modal__content .content--flex {
    flex-wrap: wrap
  }

  .modal__content #asset__link, .modal__content #asset__description {
    font-size: 2em;
  }

  .content--flex.content--second>div.half:nth-of-type(1) {
    margin-bottom: 2em;

  }

  .content__back {
    bottom: 1em;
  }

  .footer__links .spacer {
    display: none;

  }

  #work .marquee, .marquee {

    font-size: 8vh;
    height: auto;
    width: 100%;
    left: -50%;
    letter-spacing: 1px;

  }

  .info__container>.block {
    margin-bottom: 1em;
  }

  ul {
    list-style: none
  }

  .marquee__inner {
    left: -13.5vh;
    right: 0;
    padding-bottom: 2vw;
  }

  #services {
    padding-left: 5%;
    padding-right: 5px;
  }

  .footer__links {
    top: 0;
  }

  /* .footer_UA {
    display: flex;
    height:100%;
    justify-content: center;
    margin: auto;
    align-items: center;
  } */
  #about .text {
    padding-left: 0;
  }

  #contact>.flex {
    padding-top: 10vh;
  }

  #contact {
    padding: 1%;
  }

  #contact h1.title {
    width: 100%;
  }

  footer {
    height: 10vh !important;
  }

}

.polyfill_white {
  fill: white;
}

.circleStyles {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 11px
}